import React, { useEffect } from 'react'
import './PrivateRental.css'

function PrivateRental() {
    useEffect(() => {
        // Dynamically load the Tally embed script
        const script = document.createElement("script");
        script.src = "https://tally.so/widgets/embed.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Clean up script on component unmount
            document.body.removeChild(script);
        };
    }, []);
    return (
        <div style={{
            position: "relative", width: "100%",
            // height: "100vh",
            height: "85vh",
            overflow: "hidden"
        }}>
            <div style={{ textAlign: 'center', color: 'white', padding: '10px', height: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: 'black' }}>
                Due to self-hire insurance issues private hire is unavailable for foreseeable future.
                <br />
                We can accomodate for people who are willing to pay for their own insurance and show proof only.
                <br />
                Please contact us at royzrental@gmail.com for enquiries.
            </div>

            {/* <iframe
                src="https://tally.so/r/mJVgZY"
                title="Rent directly from Royz smil"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                }}
            ></iframe> */}
        </div>
    )
}

export default PrivateRental

// TYPEFORM OLD
// import React from 'react'
// import './PrivateRental.css'
// import { Widget } from '@typeform/embed-react'

// function PrivateRental() {
//     return (
//         <div style={{ width: '100%', minHeight: '85vh', backgroundColor: '#590080' }}>
//             <Widget id="puLFduQv" className="my-form" style={{ width: '100%', minHeight: '85vh' }} />
//         </div>
//     )
// }

// export default PrivateRental